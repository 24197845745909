import {initializeApp} from "firebase/app";
import {initializeFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBMs76SLeG07Iyi4pUR5cMSHy969oTD9Sg",
  authDomain: "oplit-prod.firebaseapp.com",
  //databaseURL: "https://oplit-staging.firebaseio.com",
  projectId: "oplit-prod",
  storageBucket: "oplit-prod.appspot.com",
  messagingSenderId: "267639187932",
  appId: "1:267639187932:web:6fe61ab2daadf1104d8719",
  measurementId: "G-79DG1D8J1H"
};

const BACKEND_URL = "https://api-front-yxm35fyteq-ew.a.run.app/";
const BACKEND_IMPORT_URL = "https://api-import-yxm35fyteq-ew.a.run.app";
const BACKEND_SSE_URL = "https://api-import-yxm35fyteq-ew.a.run.app";
const BACKEND_DATA_URL = "https://data-api-yxm35fyteq-ew.a.run.app";
const ENABLE_MICROSOFT_SSO = "" === "true";

const firebaseApp = initializeApp(firebaseConfig);
const firestoreDB = initializeFirestore(firebaseApp, {
  experimentalAutoDetectLongPolling: true,
});

const environment = "prod" as string;

const segment_key = "W0CzDyoPyPrFBRejNkXg8HdEZjJysft7";

export {
  firebaseConfig,
  BACKEND_URL,
  BACKEND_IMPORT_URL,
  BACKEND_SSE_URL,
  BACKEND_DATA_URL,
  ENABLE_MICROSOFT_SSO,
  environment,
  segment_key,
  firestoreDB
};

export default firebaseApp;
