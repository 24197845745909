import qs from "qs";
import {PathLike} from "fs";
import _ from "lodash";
import firebaseApp, {
  BACKEND_URL,
  BACKEND_IMPORT_URL,
  environment,
  BACKEND_DATA_URL,
  BACKEND_SSE_URL,
} from "@/firebase/init";
import {getAuth} from "firebase/auth";
import {getFunctions, httpsCallable} from "firebase/functions";
import loggerHelper from "@/tscript/loggerHelper";
import {
  Article,
  Gamme,
  GenericObject,
  Operator,
  NomenclatureProduct,
  SchedulingFilter,
  ServerDataTableParams,
  MercateamEmployee,
} from "@/interfaces";
import {
  DailyLoadNew,
  DailyLoadWithInfosAndLeadTimes,
  DailyProd,
  ParameterSchedulingDisplay,
  ParametersSchedulingRuleAIDataBySector,
  ReschedulingLogs,
  SchedulingSimulationData,
  SectorCalendar,
  levelCorresp,
  type ConwipTicketsGauge,
  StoredConwipTicketsGauge,
  SchedulingOperation,
  ApiBackend,
} from "@oplit/shared-module";
import {SSE} from "sse.js";
import {storeToRefs} from "pinia";
import {ConcurrencyManager} from "axios-concurrency";
// const axios = require("axios").default;
import axios, {AxiosError} from "axios";

/**
 * ERR_RETURN : code migrated as per the removal of pgMixin (OPL-2624)
 *
 * FIXME: this returns a fallback value because of a deficit of time to treat it properly
 * the code of components using this method should be rewritten so that this method can be rewritten in a more adequate way
 */

// a concurrency parameter of 1 makes all api requests secuential
const MAX_CONCURRENT_REQUESTS = 50;

export const apiConfig = {
  returnRejectedPromiseOnError: true,
  timeout: 60 * 1000 * 1000, //1 minute timeout
  baseURL: BACKEND_URL,
  paramsSerializer: {
    encode: (params: PathLike) => qs.stringify(params, {indices: false}),
    serialize: (params: PathLike) => qs.stringify(params, {indices: false}),
    indexes: false, // array indexes format (null - no brackets, false (default) - empty brackets, true - brackets with indexes)
  },
};

export default class APIClient {
  rootAPIPath = "api";
  apiClient: any;
  userTokenId: string;
  storeModule: any;
  abortControllers: Map<string, AbortController>;

  overrideFirebaseConfig: any | undefined;

  constructor(newApiConfig: any = {}, overrideFirebaseConfig?: any) {
    this.abortControllers = new Map();
    this.apiClient = axios.create({
      ...apiConfig,
      ...(newApiConfig || {}),
    });
    this.userTokenId = "";
    this.overrideFirebaseConfig = overrideFirebaseConfig;
    import("@/stores/mainStore").then((storeModule) => {
      this.storeModule = storeModule;
    });

    // limit the number of simultaneous requests
    ConcurrencyManager(this.apiClient, MAX_CONCURRENT_REQUESTS);
  }

  addNewController(url: string, prefix: string) {
    const controller = new AbortController();
    const controllerID = [prefix, url, Date.now()].join("-");

    this.abortControllers.set(controllerID, controller);

    return {controller, controllerID};
  }

  cancelOngoingRequests() {
    this.abortControllers.forEach((controller) => {
      controller.abort();
    });
    this.abortControllers.clear();
  }

  getRoutePath(members: string[]): string {
    return Array.from(
      [this.rootAPIPath, ...members],
      (member: string) => `/${member}`,
    ).join("");
  }

  setUserTokenId(userTokenId: string) {
    this.userTokenId = userTokenId;
  }

  async getUserTokenId() {
    const auth = this.overrideFirebaseConfig
      ? getAuth(this.overrideFirebaseConfig)
      : getAuth();
    const token: string = await auth.currentUser?.getIdToken();
    return token;
  }

  async getToken() {
    const auth = this.overrideFirebaseConfig
      ? getAuth(this.overrideFirebaseConfig)
      : getAuth();
    const token: string = await auth.currentUser?.getIdToken();
    this.setUserTokenId(token);
  }
  getAnonymousHeaders() {
    const h = {
      headers: {
        common: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-forward-to": ApiBackend.FRONT,
        },
      },
    };
    return h;
  }
  async getHeaders(use_basic_auth = false, backend = ApiBackend.FRONT) {
    let client_id: string;
    if (this.storeModule) {
      const mainStore = this.storeModule.useMainStore();
      const {userData} = storeToRefs(mainStore);
      client_id = userData.value?.client_id;
    }

    await this.getToken();
    return {
      headers: {
        common: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: use_basic_auth
            ? `Basic ${process.env.VUE_APP_BASIC_TOKEN_AUTH}`
            : this.userTokenId,
          client_id,
          "x-forward-to": backend,
        },
      },
    };
  }

  async getRequest<T = any>(
    url: string,
    extra: any = {},
    backend = ApiBackend.FRONT,
  ): Promise<T> {
    const {controller, controllerID} = this.addNewController(url, "get");

    try {
      const response = await this.apiClient.get(url, {
        ...(await this.getHeaders(false, backend)),
        ...extra,
        signal: controller.signal,
      });

      return response?.data;
    } finally {
      this.abortControllers.delete(controllerID);
    }
  }

  async postRequest<T = any>(
    url: string,
    payload: unknown = {},
    params: {use_import_url?: boolean; use_basic_auth?: boolean} = {},
  ): Promise<T> {
    const {controller, controllerID} = this.addNewController(url, "post");

    const {use_import_url, use_basic_auth} = params;
    const preUrlToUse = use_import_url ? BACKEND_IMPORT_URL : "";

    try {
      const response = await this.apiClient.post(
        `${preUrlToUse}${url}`,
        payload,
        {
          ...(await this.getHeaders(
            use_basic_auth,
            use_import_url ? ApiBackend.IMPORT : ApiBackend.FRONT,
          )),
          signal: controller.signal,
        },
      );

      return response?.data;
    } finally {
      this.abortControllers.delete(controllerID);
    }
  }

  async postSSERequest<T = any>(
    url: string,
    payload: unknown = {},
  ): Promise<T> {
    const evsource = new SSE(`${BACKEND_IMPORT_URL}${url}`, {
      payload: JSON.stringify(payload),
      headers: (await this.getHeaders(false, ApiBackend.IMPORT))?.headers
        ?.common,
    });
    return evsource;
  }

  async warmup() {
    const functions = getFunctions(firebaseApp, "europe-west1");
    const warmup = httpsCallable(functions, "warmup");
    const result = await warmup();
    return result?.data;
  }

  async getStatus() {
    const resultAxios: any = await this.apiClient.get(
      "/status",
      this.getAnonymousHeaders(),
    );
    return resultAxios?.data;
  }

  async getUsers(clientId: string) {
    const resultAxios: any = await this.apiClient.get(
      `/users/${clientId}`,
      await this.getHeaders(),
    );
    return resultAxios?.data;
  }

  async getFabriqConnectionStatus() {
    const resultAxios: any = await this.apiClient.get(
      "/api/fabriq/check_connection",
      await this.getHeaders(),
    );
    return resultAxios?.data;
  }

  async getFabriqTicket(ticketId: string) {
    const resultAxios: any = await this.apiClient.get(
      "/api/fabriq/get_ticket/" + ticketId,
      await this.getHeaders(),
    );
    return resultAxios?.data;
  }

  async postFabriqTicket(ticket: any, ticketId = "", secteur: any = {}) {
    let teamId: any;
    const teams = [
      {
        name: "SOL 1",
        teamId: 56,
      },
      {
        name: "SOL - 1",
        teamId: 56,
      },
      {
        name: "SOL 2",
        teamId: 63,
      },
      {
        name: "SOL 3",
        teamId: 70,
      },
      {
        name: "LIXUS",
        teamId: 287,
      },
      {
        name: "Melisey",
        teamId: 284,
      },
      {
        name: "Boucle Chaude",
        teamId: 307, //BSA - UAP BC
      },
      {
        name: "OUTILLAGE",
        teamId: 307, //BSA - UAP BC
      },
      {
        name: "Boucle Froide et TTH",
        teamId: 308, //BSA - UAP BF
      },
      {
        name: "SST & Labo",
        teamId: 308, //BSA - UAP BF
      },
    ];
    if (environment === "staging") teamId = 523;
    // else teamId = 588;
    else {
      const match = teams.find(
        (t: any) =>
          (secteur.name || "").includes(t.name) ||
          (secteur.factory_name || "").includes(t.name) ||
          (secteur.site_name || "").includes(t.name),
      );
      teamId = match?.teamId;
    }
    const resultAxios: any = await this.apiClient.post(
      "/api/fabriq/create_ticket/" + ticketId,
      {ticket, teamId},
      await this.getHeaders(),
    );
    return resultAxios?.data;
  }

  async deleteFabriqTicket(ticketId = "") {
    const resultAxios: any = await this.apiClient.post(
      "/api/fabriq/delete_ticket/" + ticketId,
      {},
      await this.getHeaders(),
    );
    return resultAxios?.data;
  }

  async getFabriqUsers() {
    const resultAxios: any = await this.apiClient
      .get("/api/fabriq/get_users", await this.getHeaders())
      .catch((e: any) => loggerHelper.log(e));
    return resultAxios?.data;
  }

  async getMercateamConnectionStatus() {
    try {
      const results = await this.getRequest(`/api/mercateam/get_status`);
      return [null, results];
    } catch (error) {
      loggerHelper.log(error);
      return [error, null];
    }
  }

  async getMercateamEmployees() {
    const resultAxios: {data: MercateamEmployee[]} = await this.apiClient
      .get("/api/mercateam/get_employees_list", await this.getHeaders())
      .catch((e: any) => loggerHelper.log(e));

    return resultAxios?.data;
  }

  async getMercateamPlannings(query: {
    startDate: string;
    endDate: string;
    sectorId: string;
  }) {
    const qs = new URLSearchParams(query).toString();
    const resultAxios: any = await this.apiClient
      .get(`/api/mercateam/get_plannings?${qs}`, await this.getHeaders())
      .catch((e: any) => loggerHelper.log(e));

    return resultAxios?.data;
  }

  async getMercateamAbsences(query: {
    startDate: string;
    endDate: string;
    sectorId: string;
  }) {
    const qs = new URLSearchParams(query).toString();
    const resultAxios: any = await this.apiClient
      .get(`/api/mercateam/get_absences?${qs}`, await this.getHeaders())
      .catch((e: any) => loggerHelper.log(e));

    return resultAxios?.data;
  }

  async saveMercateamPlannings(params: any) {
    const resultAxios: any = await this.apiClient
      .post("/api/mercateam/save_plannings", params, await this.getHeaders())
      .catch((e: any) => loggerHelper.log(e));

    return resultAxios?.data;
  }

  async saveUnexpectedEventsFromMercateam(params: any) {
    const resultAxios: any = await this.apiClient
      .post(
        "/api/mercateam/save_unexpected_events",
        params,
        await this.getHeaders(),
      )
      .catch((e: any) => loggerHelper.log(e));

    return resultAxios?.data;
  }

  async getMercateamSectors(params: any) {
    const resultAxios: any = await this.apiClient
      .post("/api/mercateam/get_sectors", params, await this.getHeaders())
      .catch((e: any) => loggerHelper.log(e));

    return resultAxios?.data;
  }

  async postLinearUpdateLabels(params: any = {}) {
    const resultAxios: any = await this.apiClient
      .post(
        "/api/connectors/linear/update_labels",
        params,
        await this.getHeaders(),
      )
      .catch((e: any) => loggerHelper.log(e));

    return resultAxios?.data;
  }

  async postEventChange(event: any, type = "added") {
    const resultAxios: any = await this.apiClient.post(
      "/api/events/on_change",
      {event, type},
      await this.getHeaders(),
    );
    return resultAxios?.data;
  }

  async postEventChangeAndListen(
    event: any,
    type = "added",
    extraParams?: {
      oldEvent?: Event;
      shouldRecalculateCapaOnDeletion?: boolean;
    },
  ) {
    const evsource = new SSE(`${BACKEND_SSE_URL}/api/events/on_change_sse`, {
      payload: JSON.stringify({event, type, ...(extraParams || {})}),
      headers: (await this.getHeaders(false, ApiBackend.IMPORT))?.headers
        ?.common,
    });
    return evsource;
  }

  async postSchedulingOFsChangeAndListen(data: any) {
    const evsource = new SSE(
      `${BACKEND_SSE_URL}/api/scheduling/sse_production_orders`,
      {
        payload: JSON.stringify({data}),
        headers: (await this.getHeaders(false, ApiBackend.IMPORT))?.headers
          ?.common,
      },
    );
    return evsource;
  }

  async postClientMigrateChangeAndListen(data: any) {
    const evsource = new SSE(
      `${BACKEND_SSE_URL}/api/migration/migrate-to-target`,
      {
        payload: JSON.stringify(data),
        headers: (await this.getHeaders(false, ApiBackend.IMPORT))?.headers
          ?.common,
      },
    );
    return evsource;
  }

  async postLoadTableChangeAndListen(data: any) {
    const evsource = new SSE(
      `${BACKEND_SSE_URL}/api/simulations/load-table-sse`,
      {
        payload: JSON.stringify(data),
        headers: (await this.getHeaders(false, ApiBackend.IMPORT))?.headers
          ?.common,
      },
    );
    return evsource;
  }

  async migrateEvents(params: any) {
    const resultAxios: any = await this.apiClient.post(
      "/api/events/migrate",
      params,
      await this.getHeaders(),
    );
    return resultAxios?.data;
  }

  async genericPost(route: string, params: any = {}) {
    const resultAxios: any = await this.apiClient.post(
      route,
      params,
      await this.getHeaders(),
    );
    return resultAxios?.data;
  }

  async postImportedFile(importedFile: any) {
    try {
      const resultAxios: any = await this.apiClient.post(
        `${BACKEND_IMPORT_URL}/import/file`,
        importedFile,
        await this.getHeaders(false, ApiBackend.IMPORT),
      );
      return [resultAxios?.data, null];
    } catch (e) {
      return [null, e];
    }
  }

  async postImportUpdate(importedFile: any) {
    const resultAxios: any = await this.apiClient.post(
      "/import/update_data",
      importedFile,
      await this.getHeaders(),
    );
    return resultAxios?.data;
  }

  async postImportedRows(data: any) {
    const resultAxios: any = await this.apiClient.post(
      `${BACKEND_IMPORT_URL}/import/rows`,
      data,
      await this.getHeaders(false, ApiBackend.IMPORT),
    );
    return resultAxios?.data;
  }

  async postBigQueryGet(params: any, boostedServer = false) {
    const resultAxios = await this.apiClient.post(
      `${boostedServer ? BACKEND_IMPORT_URL : ""}/bigquery/get`,
      params,
      await this.getHeaders(
        false,
        boostedServer ? ApiBackend.IMPORT : ApiBackend.FRONT,
      ),
    );
    return resultAxios?.data;
  }

  async pgCustom(params: any = {query: "", value: []}) {
    const headers = await this.getHeaders();
    const resultAxios: any = await this.apiClient
      .post("/pg/custom", params, headers)
      .catch((e: any) => loggerHelper.log(e));
    return resultAxios?.data;
  }

  async pgParser(params: any = {query: "", value: []}) {
    const headers = await this.getHeaders();
    const resultAxios: any = await this.apiClient
      .post("/pg/parser", params, headers)
      .catch((e: any) => loggerHelper.log(e));
    return resultAxios?.data;
  }

  async pgMigrate(params: any, boostedServer = false) {
    const headers = await this.getHeaders(
      false,
      boostedServer ? ApiBackend.IMPORT : ApiBackend.FRONT,
    );
    const resultAxios: any = await this.apiClient
      .post(
        `${boostedServer ? BACKEND_IMPORT_URL : ""}/api/launch-pg-migrate`,
        params,
        headers,
      )
      .catch((e: any) => loggerHelper.log(e));
    return resultAxios?.data;
  }

  async pgSaveSchedulingEvents(params: any): Promise<{
    nb_ops_parsed: number;
    promise_id?: string;
    updated_of_ids: Record<string, boolean>;
    updated_values?: unknown[];
  }> {
    const headers = await this.getHeaders();
    const resultAxios: any = await this.apiClient
      .post("/api/scheduling/save-scheduling-events", params, headers)
      .catch((e: any) => loggerHelper.log(e));
    return resultAxios?.data;
  }

  async getMachineCenters(params: any = {}) {
    const headers = await this.getHeaders();
    const resultAxios: any = await this.apiClient
      .post("/api/scheduling/machine_centers", params, headers)
      .catch((e: any) => loggerHelper.log(e));
    return resultAxios?.data;
  }

  async getOperations(params: any = {}) {
    const headers = await this.getHeaders();
    const resultAxios: any = await this.apiClient
      .post("/api/scheduling/operations", params, headers)
      .catch((e: any) => loggerHelper.log(e));
    return resultAxios?.data;
  }

  async getProductionOrdersLeadTimes(params: any) {
    const headers = await this.getHeaders(false, ApiBackend.IMPORT);
    const resultAxios: {data: DailyLoadWithInfosAndLeadTimes[]} =
      await this.apiClient
        .post(
          `${BACKEND_IMPORT_URL}/api/scheduling/production_orders_lead_times`,
          params,
          headers,
        )
        .catch((e: any) => loggerHelper.log(e));
    return resultAxios?.data;
  }

  async postSchedulingAI(params: any = {}, useDataApi = false) {
    const headers = await this.getHeaders(
      false,
      useDataApi ? ApiBackend.DATA : ApiBackend.IMPORT,
    );
    let url = `${BACKEND_IMPORT_URL}/api/scheduling/ai`;
    if (useDataApi) url = `${BACKEND_DATA_URL}/scheduling/compute`;

    const resultAxios: any = await this.apiClient
      .post(url, params, headers)
      .catch((e: any) => loggerHelper.log(e));
    return resultAxios?.data;
  }

  async postCancelLastSchedulingAI({
    rescheduling_id,
    simulation_id,
    user_id,
  }: {
    rescheduling_id: string;
    simulation_id: string;
    user_id: string;
  }): Promise<{nb_updated_ops: number} | null> {
    try {
      const result = await this.postRequest(
        "/api/scheduling/cancel_last_rescheduling_ai",
        {rescheduling_id, simulation_id, user_id},
      );
      return result;
    } catch (error) {
      return null;
    }
  }

  async setNeededOrgaForSector(params: {
    client_id: string;
    simulation_id: string;
    force_today: string;
    userData: Record<string, any>;
    sector: Record<string, any>;
    fields: Record<string, any>[];
    periode: [startDate: string, endDate: string];
    has_stock?: boolean;
  }) {
    const createdEvents: number = await this.postRequest(
      "/api/capacities/needed-orga",
      params,
    );

    return createdEvents;
  }

  async getFullTimeNeedForSector(params: {
    client_id: string;
    msd_id: string;
    sector: Record<string, any>;
    nb_equipe_values: (string | number)[];
  }) {
    const fullTimeNeedArray: string[] = await this.postRequest(
      "/api/capacities/full-time-need",
      params,
    );

    return fullTimeNeedArray;
  }

  async parseFile(params: any = {}) {
    const headers = await this.getHeaders();
    const resultAxios: any = await this.apiClient
      .post("/files/parse", params, headers)
      .catch((e: any) => loggerHelper.log(e));
    return resultAxios?.data;
  }

  async setStocksTarget(params: any = {}) {
    const headers = await this.getHeaders();
    const resultAxios: any = await this.apiClient
      .post("/stocks/set_target", params, headers)
      .catch((e: any) => loggerHelper.log(e));
    return resultAxios?.data;
  }

  // FIXME
  async getCalendar(parameters: GenericObject): Promise<[Error, any]> {
    try {
      const {
        sectorTree,
        parsedPeriod: {startDate, endDate},
        canUseDates = true,
      } = parameters;
      if (!canUseDates) return;

      const higherLevels = [
        "secteur",
        ...levelCorresp.reduce((acc: any, level: any) => {
          const parent = sectorTree[level.type + "_id"];
          if (parent) return [...acc, level.type];
          return acc;
        }, []),
      ];
      const calendar = await this.postRequest(
        `/api/sectors/${sectorTree.id}/calendars`,
        {
          start_date: startDate,
          end_date: endDate,
          higher_levels: higherLevels,
        },
      );
      return [null, calendar];
    } catch (error) {
      return [error, null];
    }
  }

  async getDailyDelay(parameters): Promise<
    [
      Error,
      {
        pg_retard?: number[];
        raw_delay: {day_date: string; delay: string}[];
      },
    ]
  > {
    try {
      const {
        defined_periods,
        endDate,
        import_id,
        has_stock,
        load_auto_orga,
        maille,
        sector_tree,
        sector,
        simulation_id,
        startDate,
        today_date,
        keep_advance,
        real_capacity,
      } = parameters;

      let realCapacity: number | undefined;
      // For forced value
      if (typeof real_capacity === "number") realCapacity = real_capacity;
      // When the value is required (ex when the graph option is enabled)
      else if (real_capacity) {
        realCapacity =
          typeof real_capacity?.value === "number" ? real_capacity?.value : 0;
      }
      const results = await this.postRequest("/api/daily-delays", {
        start_date: startDate,
        end_date: endDate,
        real_capacity: realCapacity,
        defined_periods,
        has_stock,
        import_id,
        keep_advance,
        load_auto_orga,
        maille,
        sector_tree,
        sector,
        simulation_id,
        today_date,
      });
      return [null, results];
    } catch (error) {
      loggerHelper.log(error);
      return [error, null];
    }
  }

  async getTotalCapacity(parameters): Promise<[Error, any]> {
    try {
      const results = await this.pgCustom({
        ...parameters,
        query_type: "total_capa",
      });
      return [null, results];
    } catch (error) {
      loggerHelper.log(error);
      return [error, null];
    }
  }

  async getDailyTotalCapacity(parameters): Promise<[Error, any]> {
    try {
      const results = await this.pgCustom({
        ...parameters,
        query_type: "daily_total_capa",
      });
      return [null, results];
    } catch (error) {
      loggerHelper.log(error);
      return [error, null];
    }
  }

  async getSectorDailyLoads(parameters): Promise<[Error, any]> {
    try {
      const {
        simulation_id,
        secteur_id,
        startDate,
        endDate,
        has_stock,
        detailed = false,
        detailed_light = false,
        is_scheduling = false,
        no_done_status = false,
        secteur_type = "",
      } = parameters;

      const results = await this.postRequest(
        `/api/simulations/${simulation_id}/loads`,
        {
          secteur_id,
          start_date: startDate,
          end_date: endDate,
          has_stock,
          detailed,
          detailed_light,
          is_scheduling,
          no_done_status,
          secteur_type,
        },
      );
      return [null, results];
    } catch (error) {
      loggerHelper.log(error);
      return [error, null];
    }
  }

  async getLoadsGroupedBy(parameters: {
    simulation_id: string;
    secteur_id: string;
    secteur_type?: string;
    startDate: string;
    endDate: string;
    has_stock?: boolean;
    categories: string[];
  }): Promise<[Error, any]> {
    try {
      const {
        simulation_id,
        secteur_id,
        secteur_type,
        startDate,
        endDate,
        has_stock,
        categories,
      } = parameters;
      const results = await this.postRequest(
        `/api/simulations/${simulation_id}/load-graph`,
        {
          secteur_id,
          secteur_type,
          start_date: startDate,
          end_date: endDate,
          has_stock,
          categories,
        },
        {use_import_url: true, use_basic_auth: false},
      );
      return [null, results];
    } catch (error) {
      loggerHelper.log(error);
      return [error, null];
    }
  }

  async getSectorUnit(secteur_id: string): Promise<[Error, any]> {
    try {
      const result = await this.getRequest(`/api/sectors/${secteur_id}/units`);
      return [null, result.unite || ""];
    } catch (error) {
      loggerHelper.log(error);
      return [error, null];
    }
  }

  async getAllSectorProductions(parameters): Promise<[Error, any]> {
    const {endDate, secteur_id, detailed, team_id, startDate, group_by_sector} =
      parameters;

    try {
      const results = await this.postRequest(`/api/productions/all`, {
        end_date: endDate,
        secteur_id,
        detailed,
        team_id,
        start_date: startDate,
        group_by_sector,
      });
      return [null, results];
    } catch (error) {
      loggerHelper.log(error);
      return [error, null];
    }
  }

  async getGammes(): Promise<Gamme[] | null> {
    try {
      const result = await this.getRequest("/api/gammes");
      return result;
    } catch (error) {
      return null;
    }
  }

  async loadOfEndDateFn(
    simulationId: string,
    import_id: string,
    of_id: string,
  ) {
    const [of_end_date, theoric_date] = await Promise.all([
      this.getSimulationMaxDayDate(simulationId, of_id),
      this.loadTheoricDate(import_id, of_id),
    ]);

    return {of_end_date, theoric_date};
  }

  async articleFn(article_id: string) {
    try {
      const results = await this.postRequest("/api/article/target_stock", {
        article_id,
      });
      return _.get(results, 0);
    } catch (error) {
      return null;
    }
  }

  async getAllArticles(): Promise<Article[] | null> {
    try {
      const results = await this.postRequest("/api/article");
      return results || [];
    } catch (error) {
      return null;
    }
  }

  async ofsOpsFn(
    simulation_id: string,
    of_id: string,
    params: any = {},
  ): Promise<unknown[] | null> {
    try {
      const {
        prepare_gantt_data = false,
        start_date = "",
        end_date = "",
        prepare_sidebar_ops = false,
        is_weekend_hidden = false,
        calendars = [],
      } = params;
      // const query = pgOfQuery; // FIXME Important to avoid cyclic/race import condition ?!
      const results = await this.postRequest(
        `/api/simulations/${simulation_id || "absolute"}/loads/full`,
        {
          of_id,
          prepare_gantt_data,
          start_date,
          end_date,
          prepare_sidebar_ops,
          is_weekend_hidden,
          calendars,
        },
      );
      if (!results?.length) return [];
      return results;
    } catch (error) {
      return null;
    }
  }

  // FIXME: move to `sectors` module [start]
  async getSecteurMSDParameters(secteur_id: string): Promise<unknown[] | null> {
    try {
      const result = await this.getRequest(
        `/api/sectors/${secteur_id}/msd-parameters`,
      );
      return result;
    } catch (error) {
      return null;
    }
  }

  async getSecteurParameters(
    secteur_id: string,
    params: {secteur_type: string},
  ): Promise<unknown[] | null> {
    try {
      const result = await this.postRequest(
        `/api/sectors/${secteur_id}/parameters`,
        {secteur_type: params.secteur_type},
      );
      return result;
    } catch (error) {
      return null;
    }
  }

  async getSecteurParametersFields(
    secteur_id: string,
  ): Promise<unknown[] | null> {
    try {
      const result = await this.getRequest(
        `/api/sectors/${secteur_id}/parameters/fields`,
      );
      return result;
    } catch (error) {
      return null;
    }
  }

  async getSecteurIdsWithMoveX(
    secteur_id: string,
    secteur_type: string,
  ): Promise<unknown[] | null> {
    try {
      const result = await this.postRequest(
        `/api/sectors/from-type-with-movex`,
        {secteur_id, secteur_type},
      );
      return result;
    } catch (error) {
      return null;
    }
  }

  async getSecteurImportDelays(
    secteur_id: string,
    import_id: string,
    team_id?: string,
  ): Promise<unknown[] | null> {
    try {
      const result = await this.postRequest(
        `/api/sectors/${secteur_id}/delays/${import_id}`,
        {team_id},
      );
      return result;
    } catch (error) {
      return null;
    }
  }

  async getSecteurDefaultCapacities(
    secteur_id: string,
    auto_orga: boolean,
  ): Promise<unknown[] | null> {
    try {
      const result = await this.postRequest(
        `/api/sectors/${secteur_id}/default-capacities`,
        {auto_orga},
      );
      return result;
    } catch (error) {
      return null;
    }
  }

  // currently unused
  async getSecteurMercaTeamAvailableOperators(
    secteur_id: string,
  ): Promise<unknown[] | null> {
    try {
      const result = await this.getRequest(
        `/api/sectors/${secteur_id}/mercateam-available-operators`,
      );
      return result;
    } catch (error) {
      return null;
    }
  }

  async getSecteurFormulas(secteur_id: string): Promise<unknown[] | null> {
    try {
      const result = await this.getRequest(
        `/api/sectors/${secteur_id}/formulas`,
      );
      return result;
    } catch (error) {
      return null;
    }
  }

  async getSecteurUnit(secteur_id: string): Promise<string | null> {
    try {
      const result = await this.getRequest(`/api/sectors/${secteur_id}/units`);
      return result.unite || "";
    } catch (error) {
      return null;
    }
  }

  async getSecteurCalendar(
    secteur_id: string,
    start_date: string,
    end_date: string,
    higher_levels: string[],
  ): Promise<SectorCalendar[] | null> {
    try {
      const results = await this.postRequest(
        `/api/sectors/${secteur_id}/calendars`,
        {
          start_date,
          end_date,
          higher_levels,
        },
      );
      return results;
    } catch (error) {
      return null;
    }
  }

  async getSecteurOperators(
    secteur_id: string,
    secteur_type: string,
    simulation_id: string,
    start_date: string,
    end_date: string,
  ): Promise<Operator[] | null> {
    try {
      const result = await this.postRequest(
        `/api/sectors/${secteur_id}/operators`,
        {simulation_id, end_date, start_date, secteur_type},
      );
      return result;
    } catch (error) {
      return null;
    }
  }

  async getSecteurDelays(
    secteur_id: string,
    start_date: string,
    end_date: string,
    team_id?: string,
  ): Promise<unknown[] | null> {
    try {
      const result = await this.postRequest(
        `/api/sectors/${secteur_id}/delays`,
        {
          start_date,
          end_date,
          team_id,
        },
      );
      return result;
    } catch (error) {
      return null;
    }
  }
  // FIXME: move to `sectors` module [end]
  // -----------------------------------------
  // FIXME: move to `imports` module [start]

  async getLastImport(
    team_id?: string,
    import_type = "ofs",
  ): Promise<{
    import_id: string;
    import_date: string;
    import_name: string;
  } | null> {
    try {
      const result = await this.postRequest("/api/imports/last", {
        import_type,
        team_id,
      });
      return result;
    } catch (error) {
      return null;
    }
  }

  async getImportDailyRawLoads(
    import_id: string,
    secteur_id: string,
  ): Promise<unknown[] | null> {
    try {
      const results = await this.postRequest(
        `/api/imports/${import_id}/daily-raw-loads`,
        {secteur_id},
      );
      return results;
    } catch (error) {
      return null;
    }
  }

  // FIXME: the request should be split from the treatment
  async isLateFn(
    secteur_id: string,
    secteur_type: string,
    simulation_id: string,
    has_movex_id: boolean,
  ): Promise<number> {
    try {
      const results = await this.postRequest("/api/imports/late", {
        secteur_id,
        secteur_type,
        simulation_id,
        has_movex_id,
      });
      if (!results?.length) return 0;
      const value = results[0].load || 0;
      if (!isNaN(value)) return +value;
      return 0;
    } catch (error) {
      return 0;
    }
  }

  // FIXME: the request should be split from the treatment
  async initialStockFn(
    secteur_id: string, //can be article id as well
    import_ids: string[],
    params?: {detailed?: boolean; by_article?: boolean},
  ): Promise<unknown[] | number> {
    const {detailed = false, by_article = false} = params || {};
    const defaultReturn = detailed ? [] : 0;
    try {
      if (!import_ids.length) return defaultReturn;
      const results = await this.postRequest(
        "/api/imports/detailed-initial-stocks",
        {import_ids, by_article, detailed, secteur_id},
      );
      if (detailed) return results || [];
      if (!results?.length) return 0;
      const numerizedInitialStock = +results[0].initial_stock;
      return isNaN(numerizedInitialStock) ? 0 : numerizedInitialStock;
    } catch (error) {
      return defaultReturn;
    }
  }

  async loadTheoricDate(
    import_id: string,
    of_id: string,
  ): Promise<string | null> {
    try {
      const theoric_date = await this.getRequest(
        `/api/imports/${import_id}/ofs/${of_id}/last-date`,
      );
      return theoric_date;
    } catch (error) {
      return null;
    }
  }

  async getImportLoads(
    import_id: string,
    secteur_ids: string[],
    team_id?: string,
  ): Promise<unknown[] | null> {
    try {
      const importLoads = this.postRequest(`/api/imports/${import_id}loads`, {
        secteur_ids,
        team_id,
      });
      return importLoads;
    } catch (error) {
      return null;
    }
  }

  async getImportsLoads(
    secteur_ids: string[],
    start_date: string,
    end_date: string,
    team_id?: string,
  ): Promise<unknown[] | null> {
    try {
      const loads = this.postRequest("/api/imports/loads", {
        secteur_ids,
        start_date,
        end_date,
        team_id,
      });
      return loads;
    } catch (error) {
      return null;
    }
  }

  async getRefArticles(import_id: string): Promise<string[] | null> {
    try {
      const results = await this.postRequest(
        `/api/imports/${import_id}/articles/refs`,
        {},
      );
      return (results || [])
        .map(({ref_article}: {ref_article: string}) => ref_article)
        .filter(Boolean);
    } catch (error) {
      return null;
    }
  }

  // FIXME: move to `imports` module [end]
  // -----------------------------------------
  // FIXME: move to `simulations` module [start]
  async getSimulationsDailyLoads(
    simulation_id: string,
    secteur_ids: string[],
    params: {
      startDate?: string;
      endDate?: string;
      withOFId?: boolean;
      withoutProdTarget?: boolean;
      refArticles?: string[];
      fieldsToReturn?: string[];
    } = {},
  ): Promise<DailyLoadNew[] | null> {
    try {
      const results = await this.postRequest(
        `/api/simulations/${simulation_id}/daily-loads`,
        {
          secteur_ids,
          ref_articles: params.refArticles,
          start_date: params.startDate,
          end_date: params.endDate,
          with_of_id: params.withOFId,
          without_prod_target: params.withoutProdTarget,
          fields_to_return: params.fieldsToReturn,
        },
      );
      return results;
    } catch (error) {
      return null;
    }
  }

  async getSimulationEventsInfos(
    simulation_id: string,
    params: {
      endDate?: string;
      event_status?: string;
      event_type?: string;
      group_by_event_id?: boolean;
      macro_event_ids?: string[];
      secteur?: {id: string; type: string};
      startDate?: string;
      only_simple_event?: boolean;
    } = {},
  ): Promise<unknown[] | null> {
    try {
      const results = this.postRequest(
        `/api/simulations/${simulation_id}/events-infos`,
        {
          end_date: params.endDate,
          event_status: params.event_status,
          event_type: params.event_type,
          group_by_event_id: params.group_by_event_id,
          macro_event_ids: params.macro_event_ids,
          sector_id: params.secteur,
          start_date: params.startDate,
          only_simple_event: params.only_simple_event,
        },
      );
      return results || [];
    } catch (error) {
      return null;
    }
  }

  async getSimulationSecteurLoads(
    simulation_id: string,
    params: {
      op_status?: string[];
      secteur_ids?: string[];
      full?: boolean;
      with_of_id?: boolean;
      with_prod_target?: boolean;
    } = {},
  ): Promise<
    [
      unknown,
      (
        | Partial<DailyLoadNew & SchedulingSimulationData & {name: string}>[]
        | null
      ),
    ]
  > {
    try {
      const results = await this.postRequest<
        Partial<DailyLoadNew & SchedulingSimulationData & {name: string}>[]
      >(`/api/simulations/${simulation_id}/sectors-loads`, {
        op_status: params.op_status,
        secteur_ids: params.secteur_ids,
        full: params.full,
        with_of_id: params.with_of_id,
        with_prod_target: params.with_prod_target,
      });
      return [null, results];
    } catch (error) {
      loggerHelper.log(error);
      return [error, null];
    }
  }

  async getMsdFieldValue(parameters): Promise<[Error, any]> {
    try {
      const results = await this.pgCustom({
        ...parameters,
        query_type: "msd_field_value",
      });
      return [null, results];
    } catch (error) {
      loggerHelper.log(error);
      return [error, null];
    }
  }

  // FIXME: should be named differently
  async chargeFn(
    secteur_id: string,
    simulation_id: string,
    startDate: string,
    endDate: string,
    params: {
      detailed?: boolean;
      // detailed_light will be prioritized over detailed
      detailed_light?: boolean;
      is_scheduling?: boolean;
      no_done_status?: boolean;
      secteur_type?: string;
      has_stock?: boolean;
      should_aggregate_lower_levels?: boolean;
    } = {},
  ): Promise<DailyLoadNew[] | null> {
    try {
      const results = await this.postRequest(
        `/api/simulations/${simulation_id}/loads`,
        {
          secteur_id,
          start_date: startDate,
          end_date: endDate,
          has_stock: params.has_stock ?? false,
          detailed: params.detailed ?? false,
          detailed_light: params.detailed_light ?? false,
          is_scheduling: params.is_scheduling ?? false,
          no_done_status: params.no_done_status ?? false,
          secteur_type: params.secteur_type ?? "",
          should_aggregate_lower_levels:
            params.should_aggregate_lower_levels ?? false,
        },
      );

      return results;
    } catch (error) {
      return null;
    }
  }

  async getSimulationMaxDayDate(
    simulation_id: string,
    of_id: string,
  ): Promise<unknown[] | null> {
    try {
      const results = this.getRequest(
        `/api/simulations/${
          simulation_id || "absolute"
        }/ofs/${of_id}/last-date`,
      );
      return results;
    } catch (error) {
      return null;
    }
  }

  async importIdFn(
    simulation_id: string,
    import_types: string[] = ["ofs"],
    raw = false,
  ): Promise<(unknown | string)[] | null> {
    try {
      const results = await this.postRequest(
        `/api/simulations/${simulation_id}/imports`,
        {import_types},
      );
      return raw ? results : _.map(results, "import_id");
    } catch (error) {
      // see ERR_RETURN
      return [];
    }
  }
  // FIXME: move to `simulations` module [end]
  // -----------------------------------------
  // FIXME: move to `productions` module [start]
  // returns the latest date at which an import for the production has been realized by the team
  async getLastProdUpdate({
    secteur_id,
    team_id,
  }: {
    secteur_id: string;
    team_id: string;
  }): Promise<string | null> {
    try {
      const {last_prod_update} = await this.postRequest(
        "/api/productions/last_prod_update",
        {
          secteur_id,
          team_id: team_id || "",
        },
      );
      return last_prod_update || null;
    } catch (error) {
      return null;
    }
  }

  async allProdFn(
    secteur_id: string,
    parsedPeriod: any,
    team_id = "",
    detailed = false,
    group_by_sector = false,
  ): Promise<DailyProd[]> {
    try {
      const {startDate, endDate} = parsedPeriod || {};

      const results = await this.postRequest("/api/productions/all", {
        end_date: endDate,
        secteur_id,
        detailed,
        team_id,
        start_date: startDate,
        group_by_sector,
      });
      if (!results?.length) return [];
      return results;
    } catch (error) {
      // see ERR_RETURN above
      return [];
    }
  }
  // FIXME: move to `productions` module [end]
  // -----------------------------------------
  // FIXME: move to `events` module [start]

  async fieldEventsFn(
    simulation_id: string,
    secteur_id: string,
    secteur_type: string,
    field: string,
    parsedPeriod: any = {},
  ) {
    const {startDate, endDate} = parsedPeriod;

    const events = await this.postRequest("/api/events/fields", {
      end_date: endDate,
      field,
      secteur_id,
      secteur_type,
      simulation_id,
      start_date: startDate,
    });

    if (!events?.length) return [];
    return events;
  }

  async eventsFn(
    simulation_id: string,
    secteur_id: string,
    secteur_type: string,
    parsedPeriod: any = {},
  ) {
    const {startDate, endDate} = parsedPeriod || {};

    const events = await this.postRequest("/api/events/details", {
      start_date: startDate,
      end_date: endDate,
      secteur_id,
      secteur_type,
      simulation_id,
    });

    if (!events?.length) return [];
    return _.uniqBy(events, "id");
  }
  // FIXME: move to `events` module [end]
  // -----------------------------------------

  async recoverArchivedData(simulation_id: string) {
    try {
      const resultAxios: any = await this.apiClient.post(
        `${BACKEND_IMPORT_URL}/api/archives/recover-simulation`,
        {simulation_id},
        await this.getHeaders(false, ApiBackend.IMPORT),
      );
      return [resultAxios?.data, null];
    } catch (e) {
      return [null, e];
    }
  }

  async handleCustomCapa(params) {
    return await this.pgCustom(params);
  }

  async handleCustomCharge(params) {
    return await this.pgCustom({...params, query_type: "charge"});
  }

  async setDemand(params) {
    return await this.pgCustom({
      ...params,
      query_type: "set_demand",
      startDate: params.startDate,
      endDate: params.endDate,
    });
  }

  async updateOpDeviations(parameters_id: string) {
    return await this.postRequest("/api/scheduling/update_op_deviations", {
      parameters_id,
    });
  }

  async getSchedulingAnalytics({
    simulationId,
    sectorIds,
    endDate,
    analyticIds,
    hasAutoOrga,
    filterDoneOps,
  }: {
    simulationId: string;
    sectorIds: string[];
    endDate: string;
    analyticIds: string[];
    hasAutoOrga?: boolean;
    filterDoneOps?: boolean;
  }): Promise<[Error, {id: string; value: number}[]]> {
    try {
      const results: {id: string; value: number}[] = await this.postRequest(
        `/api/scheduling/${simulationId}/get-analytics`,
        {
          sector_ids: sectorIds,
          end_date: endDate,
          analytic_ids: analyticIds,
          auto_orga: hasAutoOrga,
          filter_done_ops: filterDoneOps,
        },
      );
      return [null, results];
    } catch (error) {
      return [error, null];
    }
  }

  async getReschedulingLogs(params: {
    rescheduling_id: string;
    ai_data_by_sector: ParametersSchedulingRuleAIDataBySector[];
    pace_on_extra_field: string;
    stations: {id: string; parent_text: string}[];
    i18n_keys: Record<string, any>;
  }): Promise<
    [
      Error,
      {raw_logs?: ReschedulingLogs[]; download_url?: string; filename?: string},
    ]
  > {
    try {
      const reschedulingLogs = await this.postRequest(
        "/api/scheduling/get-rescheduling-logs",
        params,
      );
      return [null, reschedulingLogs];
    } catch (e) {
      return [e, null];
    }
  }

  // params type is ISchedulingGetSubOFsRequest
  async getSubOFs(params: {of_id: string; simulation_id: string}) {
    try {
      const {sub_ofs, sub_ofs_count} = await this.postRequest(
        "/api/scheduling/get-sub-ofs",
        params,
      );
      return [null, {sub_ofs, sub_ofs_count}];
    } catch (e) {
      return [e, null];
    }
  }

  // params type is ISchedulingGetQuantiteOFRequest
  async getQuantiteOF(params: {of_id: string; simulation_id: string}) {
    try {
      const result = await this.postRequest<{quantite_of: number}>(
        "/api/scheduling/get-quantite-of",
        params,
      );
      return [null, result];
    } catch (e) {
      return [e, null];
    }
  }

  async getNextAvailableCapaDay(params: {
    of_ids: string[];
    simulation_id: string;
    new_date: string;
    sector_id: string;
    calendars: SectorCalendar[];
    op_duration?: number;
  }) {
    try {
      const nextAvailableCapaDay = await this.postRequest(
        "/api/scheduling/get-next-available-capa-day",
        params,
      );
      return [null, nextAvailableCapaDay];
    } catch (e) {
      return [e, null];
    }
  }

  async getNomenclatures() {
    try {
      const results: NomenclatureProduct[] = await this.getRequest(
        "/api/nomenclatures/get-all",
      );
      return results;
    } catch (error) {
      loggerHelper.log(error);
      return [];
    }
  }

  async updateNomenclatures(
    data: NomenclatureProduct[],
  ): Promise<NomenclatureProduct[] | {error: Error}> {
    try {
      const results = (await this.postRequest("/api/nomenclatures/update", {
        data,
      })) as NomenclatureProduct[];
      return results;
    } catch (error) {
      loggerHelper.log(error);
      throw new Error(error);
    }
  }

  async getCustomerInterfaceListOfs({
    table_params,
    table_filters,
  }: {
    table_params: ServerDataTableParams;
    table_filters: SchedulingFilter[];
  }): Promise<
    [
      Error | null,
      {
        ofs: any[];
        total_count: number;
      } | null,
    ]
  > {
    try {
      const results = await this.postRequest(
        "/api/customer-interface/list-ofs",
        {
          table_params,
          table_filters,
        },
      );
      return [null, results];
    } catch (e) {
      return [e, null];
    }
  }

  async getCustomerInterfaceFilters(): Promise<
    [Error | null, Record<string, string[]> | null]
  > {
    try {
      const filterValues = await this.getRequest(
        "/api/customer-interface/get-filters",
      );
      return [null, filterValues];
    } catch (e) {
      return [e, null];
    }
  }

  async getCustomerInterfaceOfsDetails(): Promise<
    [Error | null, any[] | null]
  > {
    try {
      const ofsDetails = await this.postRequest(
        "/api/customer-interface/get-ofs-details",
      );
      return [null, ofsDetails];
    } catch (e) {
      return [e, null];
    }
  }

  async getCustomersDetails(): Promise<
    [Error | null, {id: string; name: string}[] | null]
  > {
    try {
      const customersDetails = await this.getRequest(
        "/api/customer-interface/get-customers-details",
      );
      return [null, customersDetails];
    } catch (e) {
      return [e, null];
    }
  }

  async getProviderIds(): Promise<[Error | null, string[] | null]> {
    try {
      const providerIds = await this.getRequest(
        "/api/customer-interface/get-provider-ids",
      );
      return [null, providerIds];
    } catch (e) {
      return [e, null];
    }
  }

  async getLoadQuantities(params: {
    simulation_id: string;
    is_stocks?: boolean;
    has_stock?: boolean;
  }): Promise<
    [
      Error | null,
      {
        load?: number;
        unite_op?: string;
        quantite_op_2?: number;
        unite_op_2?: string;
        quantite_of?: number;
        unite_of?: string;
      },
    ]
  > {
    try {
      const {simulation_id, is_stocks, has_stock} = params;
      const op = await this.postRequest<DailyLoadNew>(
        `/api/simulations/${simulation_id}/get-load-quantities`,
        {is_stocks, has_stock},
      );
      return [null, op];
    } catch (error) {
      return [error, null];
    }
  }

  async getListOfs(params: {
    simulation_id: string;
    table_params: ServerDataTableParams;
    table_filters: SchedulingFilter[];
    compute_predictions: boolean;
    scheduling_display: Partial<ParameterSchedulingDisplay>;
  }): Promise<
    [
      Error | null,
      {
        ofs: any[];
        total_count: number;
      } | null,
    ]
  > {
    try {
      const results = await this.postRequest(
        "/api/scheduling/list-ofs",
        params,
      );
      return [null, results];
    } catch (e) {
      return [e, null];
    }
  }

  async getOfsFilters(
    simulation_id: string,
  ): Promise<[Error | null, Record<string, string[]> | null]> {
    try {
      const filterValues = await this.postRequest(
        "/api/scheduling/get-ofs-filters",
        {simulation_id},
      );
      return [null, filterValues];
    } catch (e) {
      return [e, null];
    }
  }

  async getImportErrorLogs(
    import_id: string,
  ): Promise<[null, any] | [Error, null]> {
    try {
      const importErrorLogs = await this.postRequest(
        `/api/imports/error-logs`,
        {import_id},
      );
      return [null, importErrorLogs];
    } catch (e) {
      return [e, null];
    }
  }

  async getImportLogs(import_id: string): Promise<[null, any] | [Error, null]> {
    try {
      const importLogs = await this.postRequest(`/api/imports/import-logs`, {
        import_id,
      });
      return [null, importLogs];
    } catch (e) {
      return [e, null];
    }
  }

  async updateCapacityFromSimulation(params: {
    target_simulation_id: string;
    source_simulation_id: string;
  }): Promise<[null, null] | [Error, null]> {
    try {
      const results = await this.postRequest(
        `/api/scheduling/update-capacity-from-simulation`,
        params,
      );

      if (results) return [results, null];

      return [null, null];
    } catch (e) {
      return [e, null];
    }
  }

  async getConwipTicketsGauges(payload: {
    group_id: ConwipTicketsGauge["group_id"];
    secteur_id?: ConwipTicketsGauge["secteur_id"];
  }): Promise<[StoredConwipTicketsGauge[], null] | [null, Error]> {
    try {
      const result = await this.postRequest<StoredConwipTicketsGauge[]>(
        `/api/scheduling/get-conwip-tickets-gauges`,
        payload,
      );

      return [result, null];
    } catch (e) {
      return [null, e as Error];
    }
  }

  async updateConwipTicketsGauges(
    payload: ConwipTicketsGauge,
  ): Promise<[boolean, null] | [null, Error]> {
    try {
      const result = await this.postRequest<boolean>(
        `/api/scheduling/update-conwip-tickets-gauges`,
        payload,
      );

      return [result, null];
    } catch (e) {
      return [null, e as Error];
    }
  }

  async getOPsByOF(payload: {
    ref_article: string;
    of_id: string;
  }): Promise<[SchedulingOperation[], null] | [null, Error]> {
    try {
      const result = await this.postRequest<SchedulingOperation[]>(
        `/api/analytics/get-ops-by-of`,
        payload,
      );

      return [result, null];
    } catch (e) {
      return [null, e as Error];
    }
  }

  async postMicrosoftSync(payload: {
    idToken: string;
    accessToken: string;
  }): Promise<[boolean, null] | [null, AxiosError]> {
    try {
      await this.postRequest("/auth/msft/sync", payload);
      return [true, null];
    } catch (e) {
      return [null, e as AxiosError];
    }
  }
}
